<div class="sdui-toast {{ position }}">
  <div
    class="sdui-toast-item"
    *ngFor="let item of notifications; trackBy: notificationsTrackByFn"
  >
    <div class="sdui-toast-box d-flex align-items-center {{ cssClass(item) }}">
      <div
        [ngSwitch]="cssClass(item)"
        style="display: block"
        class="me-3 d-flex"
      >
        <ng-container *ngSwitchCase="'toast-success'">
          <em
            class="a-icon ui-ic-checkmark"
            title="checkmark"
          ></em>
        </ng-container>
        <ng-container *ngSwitchCase="'toast-info'">
          <em
            class="a-icon ui-ic-alert-info"
            title="info"
          ></em>
        </ng-container>
        <ng-container *ngSwitchCase="'toast-error'">
          <em
            class="a-icon ui-ic-alert-error"
            title="error"
          ></em>
        </ng-container>
        <ng-container *ngSwitchCase="'toast-warning'">
          <em
            class="a-icon ui-ic-alert-warning"
            title="warning"
          ></em>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <em
            class="a-icon ui-ic-checkmark"
            title="checkmark"
          ></em>
        </ng-container>
      </div>
      <div class="sdui-toast-message me-3 bsh-medium">
        <ng-container *ngIf="item.messageIsHTML; else noHTML">
          <div
            class="html-content"
            [innerHTML]="item.message"
          ></div>
        </ng-container>
        <ng-template #noHTML>
          {{ item.message }}
        </ng-template>
      </div>
      <div
        (click)="removeNotification(item)"
        class="d-flex close-btn"
      >
        <em
          class="a-icon ui-ic-close-small"
          title="close"
        ></em>
      </div>
    </div>
  </div>
</div>
